<template>
    <footer class="footer">
    </footer>
</template>

<script>
export default {
  name: 'FooterComponent',
  props: {
    msg: String
  }
}
</script>

<style scoped>
.footer {
  height: 128px;
}

.footer {
  background: repeat url("../assets/plants3.png");
}

</style>